import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Index = ({ data }) => (
  <>
    <Helmet>
      {/* DON'T REMOVE; IT KEEPS AWAY WEB CRAWLERS */}
      <meta name="robots" content="noindex" />
    </Helmet>

    <h1>Welcome to {data.site.siteMetadata.title}</h1>
    <Link to="/app">Go to the app</Link>
  </>
);

export default Index;

export const SiteTitleInIndex = graphql`
  query SiteTitleInIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
